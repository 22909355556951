export default function stickyHeader(stickyElem) {
  const initialY = stickyElem.offsetTop;
  const className = 'nav--sticky';

  const stickyHeaderHandler = function () {
    var distance = stickyElem.offsetTop - window.pageYOffset;
    var offsetY = window.pageYOffset;
    const stuck = stickyElem.classList.contains(className);
    if (!stuck && distance <= 0) {
      stickyElem.classList.add(className);
    } else if (stuck && offsetY <= initialY) {
      stickyElem.classList.remove(className);
    }
  };

  window.onscroll = stickyHeaderHandler;
  stickyHeaderHandler();
}
