import CountUp from 'countup.js';
import isInViewport from './utils/is-in-viewport';

const map = Array.prototype.map;
const ANIMATION_DURATION = 2;
let counters;

function scrollHandler() {
  let started = false;
  for (let i = 0; i < counters.length; i++) {
    const c = counters[i];
    if (isInViewport(c.element)) {
      c.counter.start();
      c.started = true;
      started = true;
      break;
    }
  }
  if (started) {
    counters.forEach((c) => {
      if (!c.started) c.counter.start();
    });
    window.removeEventListener('scroll', scrollHandler, false);
  }
}

function setupCounter(element) {
  const num = parseInt(element.textContent, 10) || 0;
  const options = {
    useEasing: false,
    useGrouping: true,
    separator: ',',
    decimal: '.',
    suffix: '%',
  };
  const counter = new CountUp(element, 0, num, 0, ANIMATION_DURATION, options);
  return { element, counter, started: false };
}

export default function setup(elem) {
  const percElements = elem.querySelectorAll('.perc');
  counters = map.call(percElements, setupCounter);
  window.addEventListener('scroll', scrollHandler, false);
}
