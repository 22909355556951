const SLIDER_TIME = 6000;
const IVCN = 'testimonial--visible';
const CVCN = 'slider-control--active';

function previousSlide() {
  const previousIndex = (this.currentIndex || this.items.length) - 1;
  goToSlide.call(this, previousIndex);
}

function nextSlide() {
  const nextIndex = (this.currentIndex + 1) % this.items.length;
  goToSlide.call(this, nextIndex);
}

function goToSlide(nextIndex) {
  const next = this.items[nextIndex];
  clearTimeout(this.timer);
  if (!next) return;

  // Controls
  const currentControl = this.controls[this.currentIndex];
  if (currentControl) currentControl.classList.remove(CVCN);
  const nextControl = this.controls[nextIndex];
  if (nextControl) nextControl.classList.add(CVCN);

  // Items
  const current = this.items[this.currentIndex];
  if (current) current.classList.remove(IVCN);
  this.currentIndex = nextIndex;
  next.classList.add(IVCN);

  this.scheduleNext();
}

function mouseEnterHandler() {
  clearTimeout(this.timer);
}

function mouseLeaveHandler() {
  const time = SLIDER_TIME / 2;
  this.scheduleNext(time);
}

function handleTouch(type) {
  this[`${type}X`] = event.changedTouches[0].screenX;
  this[`${type}Y`] = event.changedTouches[0].screenY;

  if (type === 'start') return;
  const yDelta = Math.abs(this.endY - this.startY);
  if (yDelta > 50) return;

  if (this.endX < this.startX) {
    // swiped left
    nextSlide.call(this.slider);
  }

  if (this.endX > this.startX) {
    // swiped right
    previousSlide.call(this.slider);
  }
}

export default function setup(elem) {
  const items = elem.children;
  const controls = elem.nextElementSibling.children;
  const slider = {
    items,
    controls,
    currentIndex: 0,
    timer: null,
    scheduleNext(delay) {
      delay = delay || SLIDER_TIME;
      this.timer = setTimeout(nextSlide.bind(this), delay);
    },
  };

  for (let i = 0; i < controls.length; i++) {
    const control = controls[i];
    control.onclick = (e) => {
      const index = e.target.dataset.index;
      goToSlide.call(slider, index);
    };
  }

  elem.addEventListener('mouseenter', mouseEnterHandler.bind(slider));
  elem.addEventListener('mouseleave', mouseLeaveHandler.bind(slider));

  const touchContext = {
    touchstartX: 0,
    touchstartY: 0,
    touchendX: 0,
    touchendY: 0,
    slider,
  };

  elem.addEventListener(
    'touchstart',
    handleTouch.bind(touchContext, 'start'),
    false
  );
  elem.addEventListener(
    'touchend',
    handleTouch.bind(touchContext, 'end'),
    false
  );

  slider.scheduleNext();
}
