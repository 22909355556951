import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

const FIELDS = ['name', 'email', 'phone' /*, 'activity'*/];
const MANDATORY_FIELDS = ['name', 'phone'];
const API_URL = 'https://ilove.me/contact-request';

function getFormValues(fieldElems) {
  const formValues = {};
  Object.keys(fieldElems).forEach(
    (fieldName) => (formValues[fieldName] = fieldElems[fieldName].value)
  );
  return formValues;
}

function isPhoneValid(phoneStr) {
  const phoneNumber = parsePhoneNumberFromString(`+55${phoneStr}`);
  const phoneIsValid = phoneNumber && phoneNumber.isValid();
  return phoneIsValid;
}

function validatePhoneInput(elem) {
  const phoneStr = elem.value;
  const isValid = !phoneStr || isPhoneValid(phoneStr);
  const className = 'form__input--invalid';
  elem.parentElement.classList[isValid ? 'remove' : 'add'](className);
}

function validateForm(formElem, formValues, fieldElems) {
  let isValid = true;
  MANDATORY_FIELDS.forEach((k) => {
    if (!formValues[k]) {
      isValid = false;
    }
  });

  const phoneStr = formValues.phone;
  const phoneIsValid = isPhoneValid(phoneStr);
  if (isValid && !phoneIsValid) {
    fieldElems.phone.focus();
  }
  isValid = isValid && phoneIsValid;
  // const buttonElem = formElem.querySelector('button');
  // buttonElem.classList[isValid ? 'remove' : 'add']('btn--disabled');

  return isValid;
}

function postRequest(values) {
  values.platform = 'web';
  return fetch(API_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  });
}

function submitForm(formElem, fieldElems, event) {
  if (formElem._isWorking) return;
  event.preventDefault();
  const formValues = getFormValues(fieldElems);
  if (!validateForm(formElem, formValues, fieldElems)) return false;

  try {
    window.gtag('event', 'submit', { event_category: 'form' });
  } catch (e) {
    // Ignore
  }

  formElem._isWorking = true;

  postRequest(formValues)
    .then(() => {
      const successElemSelector = 'section-form__form-sucess';
      const className = `${successElemSelector}--visible`;
      const successElem = formElem.parentElement.previousElementSibling;
      successElem.classList.add(className);
      const distance = successElem.getBoundingClientRect();
      const y = window.scrollY + distance.top - 110;
      window.scrollTo(0, y);
    })
    .catch(() =>
      alert('Ocorreu um problema. Por favor tente novamente mais tarde.')
    )
    .finally(() => {
      formElem._isWorking = false;
    });
  return false;
}

export default function contactForm(formElem) {
  const fieldElems = {};
  FIELDS.forEach(
    (f) => (fieldElems[f] = formElem.querySelector(`[name=${f}]`))
  );
  formElem.onsubmit = submitForm.bind(null, formElem, fieldElems);
  const phoneElem = fieldElems.phone;
  phoneElem.onchange = validatePhoneInput.bind(null, phoneElem);
}
