export default function setup(sectionElem) {
  const swiperElem = sectionElem.querySelector('.swiper-container');

  const swiper = new window.Swiper(swiperElem, {
    autoHeight: true,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  const buttonClickHandler = (e) => {
    e.preventDefault();
    swiper.slideNext();
  };

  const buttonElems = sectionElem.querySelectorAll(
    '.swiper-slide__txt__action a'
  );
  buttonElems.forEach((buttomElem) =>
    buttomElem.addEventListener('click', buttonClickHandler)
  );
}
