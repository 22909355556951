const ANIMATION_DELAY = 3000;
const LETTERS_DELAY = 75;

export default function setup(elem) {
  //insert <i> element for each letter of a changing word
  singleLetters(elem.querySelectorAll('b'));
  //initialise headline animation
  animateHeadline(elem);
}

function singleLetters(words) {
  words.forEach((word) => {
    const letters = word.textContent.split('');
    const selected = word.classList.contains('is-visible');
    for (let i in letters) {
      letters[i] = `<i${selected ? ' class="in"' : ''}><em>${
        letters[i]
      }</em></i>`;
    }

    const newLetters = letters.join('');
    word.innerHTML = newLetters;
  });
}

function animateHeadline(headline) {
  const firstWord = headline.querySelector('.is-visible');
  const hideIn = calcWordAnimationDelay(firstWord);
  setTimeout(() => hideWord(firstWord), hideIn);
}

function hideWord(word) {
  var nextWord = nextElement(word);

  const next = showWord.bind(null, nextWord);
  hideLetter(word.querySelector('i'), word, LETTERS_DELAY, next);

  const hideIn = calcWordAnimationDelay(nextWord);
  setTimeout(() => hideWord(nextWord), hideIn);
}

function hideLetter(letter, word, duration, next) {
  letter.classList.remove('in');
  letter.classList.add('out');

  if (letter.nextElementSibling) {
    setTimeout(
      () => hideLetter(letter.nextElementSibling, word, duration, next),
      duration
    );
  } else {
    next();
  }
}

function showWord(word) {
  const words = word.parentElement.children;
  for (let i = 0; i < words.length; i++) {
    const w = words[i];
    w.style.display = w === word ? 'inline' : 'none';
  }
  showLetter(word.querySelector('i'), word, LETTERS_DELAY);
}

function showLetter(letter, word, duration) {
  letter.classList.add('in');
  letter.classList.remove('out');

  if (!letter.nextElementSibling) return;
  setTimeout(
    () => showLetter(letter.nextElementSibling, word, duration),
    duration
  );
}

function calcWordAnimationDelay(word) {
  const factor = parseInt(word.dataset.hold, 10) || 1;
  const hideIn = factor * ANIMATION_DELAY;
  return hideIn;
}

function nextElement(elem) {
  return elem.nextElementSibling || elem.parentElement.firstElementChild;
}
