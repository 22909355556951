import './styles/main.scss';
import stickyHeader from './sticky';
import contactForm from './contact-form';
// import formSelect from './form-select';
import testimonials from './testimonials';
import headline from './headline';
import stats from './stats';
import agendon from './agendon';
import smoothScrollTo from './utils/smooth-scroll-to';

function scrollToContactForm() {
  const form = document.getElementById('contact');
  const formY = form.offsetTop - 100;
  smoothScrollTo(0, formY, 1000);

  try {
    window.gtag('event', 'goto', { event_category: 'form' });
  } catch (e) {
    // Ignore
  }
}

function init() {
  stickyHeader(document.getElementById('nav'));
  testimonials(document.getElementById('testimonials'));
  contactForm(document.getElementById('contact-form'));
  // document.querySelectorAll('.form-select').forEach(elm => formSelect(elm));
  document.querySelectorAll('.headline__words').forEach((elm) => headline(elm));
  document
    .querySelectorAll('[href="#contact"]')
    .forEach((elm) => (elm.onclick = scrollToContactForm));
  stats(document.getElementById('stats'));
  agendon(document.getElementById('agendon'));
}

init();
